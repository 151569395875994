import React, { useState } from 'react';
import pdfimg from '../../assets/pdficon.png'
import img from '../../assets/imageicon.png'
const AssignmentTask = () => {
  const [showOptions, setShowOptions] = useState(null);

  const handleOptionsClick = (id) => {
    setShowOptions((prev) => (prev === id ? null : id));
  };

  const assignments = [
    { id: 1, type: 'pdf', name: 'Work-sheet-01.pdf', date: '16/05/2024' },
    { id: 2, type: 'doc', name: 'Work-sheet-02.doc', date: '17/05/2024' },
  ];

  return (
    <div className="p-4  min-h-screen">
      {/* Header */}
      <div className="flex items-center mb-6">
        <button className="text-gray-600 hover:text-gray-900 mr-4">
          <i className="fas fa-arrow-left"></i>
        </button>
        <h1 className="text-xl font-medium text-gray-600">Day 01: Chapter 01</h1>
      </div>

      {/* Assignment Task */}
      <div>
        <h2 className="text-lg font-semibold text-gray-700 mb-4">
          Assignment Task
        </h2>
        <div className="flex items-center justify-between bg-white rounded-lg p-4 shadow mb-4">
          <div className="flex items-center">
            <img
        src={pdfimg}
              alt="PDF"
              className="w-6 h-8"
            />
            <div className="ml-4">
              <p className="text-gray-800 font-medium">Work-sheet-01.pdf</p>
              <p className="text-gray-500 text-sm">16/05/2024</p>
            </div>
          </div>
          <button className="text-gray-600 hover:text-gray-900">
            <i className="fas fa-download"></i>
          </button>
        </div>
      </div>

      {/* Your Assignment Task */}
      <div>
        <h2 className="text-lg font-semibold text-gray-700 mb-4">
          Your Assignment Task
        </h2>
        {assignments.map((assignment) => (
          <div
            key={assignment.id}
            className="relative flex items-center justify-between bg-white rounded-lg p-4 shadow mb-4"
          >
            <div className="flex items-center">
              <img
                src= {img}
                    
                
                alt={assignment.type.toUpperCase()}
                className="w-6 h-8"
              />
              <div className="ml-4">
                <p className="text-gray-800 font-medium">{assignment.name}</p>
                <p className="text-gray-500 text-sm">{assignment.date}</p>
              </div>
            </div>
            <button
              className="text-gray-600 hover:text-gray-900"
              onClick={() => handleOptionsClick(assignment.id)}
            >
              <i className="fas fa-ellipsis-v"></i>
            </button>

            {/* Dropdown Menu */}
            {showOptions === assignment.id && (
              <div className="absolute top-14 right-4 bg-white border rounded-lg shadow-lg w-40 z-10">
                <button className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100">
                  Download
                </button>
                <button className="block w-full text-left px-4 py-2 text-red-600 hover:bg-gray-100">
                  Delete
                </button>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Upload Assignment */}
      <div className="text-center mt-6">
        <button className="bg-purple-600 text-white py-2 px-6 rounded-full shadow hover:bg-purple-700">
          Upload Assignment
        </button>
      </div>
    </div>
  );
};

export default AssignmentTask;
