import React from 'react';
import img from '../../assets/mycourses.png';
import person1 from '../../assets/person1.png';
import person2 from '../../assets/person2.png';
import person3 from '../../assets/person3.png';
import person4 from '../../assets/person4.png';
import person5 from '../../assets/person5.png';

const CourseCard = ({ title, date, lessons, duration, price, originalPrice, progress }) => {
  // If `progress` is provided, show the new design
  if (progress !== undefined) {
    return (
      <div className="bg-white rounded-xl shadow-lg my-2 p-4 w-full max-w-xs hover:bg-primary hover:text-white transition duration-300">
        <div className="flex flex-col items-start text-center">
          <div className="p-2 mb-4">
            <img src={img} alt="Course Icon" className="w-10 h-10" />
          </div>
          <h3 className="font-semibold text-lg text-start mb-2">{title}</h3>
          <div className="flex items-center text-sm mb-3">
            <img src={person1} alt="Participants" className="rounded-full w-6 h-6 -mr-2" />
            <img src={person2} alt="Participants" className="rounded-full w-6 h-6 -mr-2" />
            <img src={person3} alt="Participants" className="rounded-full w-6 h-6 -mr-2" />
            <img src={person4} alt="Participants" className="rounded-full w-6 h-6 -mr-2" />
            <img src={person5} alt="Participants" className="rounded-full w-6 h-6 mr-1" />
            <span className="ml-2">+20 others</span>
          </div>
          <div className="text-sm mb-4">
            <p>{date}</p>
            <p>{lessons} Lessons • {duration}</p>
          </div>
          <div className="flex justify-between items-center gap-2 w-full">
            <div className="h-2 w-full bg-gray-200 rounded-full overflow-hidden">
              <div className="h-full rounded-r-xl bg-purple-600" style={{ width: `${progress}%` }}></div>
            </div>
            <p className="text-right text-sm">{progress}%</p>
          </div>
        </div>
      </div>
    );
  }

  // Default to the previous design
  return (
    <div className="bg-white rounded-xl shadow-lg my-2 p-4 w-full max-w-xs hover:bg-primary hover:text-white transition duration-300">
      <div className="flex flex-col items-start text-center">
        <div className="p-2 mb-4">
          <img src={img} alt="Course Icon" className="w-10 h-10" />
        </div>
        <h3 className="font-semibold text-lg text-start mb-2">{title}</h3>
        <div className="flex items-center text-sm mb-3">
          <img src={person1} alt="Participants" className="rounded-full w-6 h-6 -mr-2" />
          <img src={person2} alt="Participants" className="rounded-full w-6 h-6 -mr-2" />
          <img src={person3} alt="Participants" className="rounded-full w-6 h-6 -mr-2" />
          <img src={person4} alt="Participants" className="rounded-full w-6 h-6 -mr-2" />
          <img src={person5} alt="Participants" className="rounded-full w-6 h-6 mr-1" />
          <span className="ml-2">+20 others</span>
        </div>
        <div className="text-sm mb-4">
          <p>{date}</p>
          <p>{lessons} Lessons • {duration}</p>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center">
            <p className="font-bold text-lg mr-2">${price}</p>
            <p className="line-through">${originalPrice}</p>
          </div>
          <button className="bg-white text-primary hover:bg-white hover:text-primary flex items-center justify-center rounded-full w-7 h-7 p-2 shadow-md">
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
