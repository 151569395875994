import CourseCard from "./CourseCard";

const Courses = () => {
    const courses = [
      {
        title: "Software Design Course",
        date: "March, 2024",
        lessons: "35",
        duration: "3h 14m",
        price: "50",
        originalPrice: "50",
      },
      {
        title: "Architecture Principles Course",
        date: "March, 2024",
        lessons: "35",
        duration: "3h 14m",
        price: "50",
        originalPrice: "50",
      },
      {
        title: "3D Character Design Course",
        date: "March, 2024",
        lessons: "35",
        duration: "3h 14m",
        progress: 70
      },
    ];
  
    return (
      <div className="px-6 py-8">
        <div className="mb-6">
          <h2 className="text-xl font-bold text-gray-800 mb-4">Popular Courses</h2>
          <div className="flex gap-4 mb-4 overflow-x-auto">
            {courses.map((course, index) => (
              <CourseCard key={index} {...course} />
            ))}
          </div>
        </div>
  
        <div>
          <h2 className="text-xl font-bold mt-8 text-gray-800 mb-4">
            Recommended For You
          </h2>
          <div className="flex gap-4 overflow-x-auto">
            {courses.map((course, index) => (
              <CourseCard key={index} {...course} />
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  export default Courses;