import React, { useState } from "react";
import img1 from "../../assets/person1.png";
import img2 from "../../assets/person2.png";
import star from "../../assets/reviewstar.png";

const TeacherProfile = () => {
  const [isEducationalOpen, setEducationalOpen] = useState(true);
  const [isWorkOpen, setWorkOpen] = useState(false);
  const [isCourseOpen, setCourseOpen] = useState(false);

  const toggleEducational = () => setEducationalOpen(!isEducationalOpen);
  const toggleWork = () => setWorkOpen(!isWorkOpen);
  const toggleCourse = () => setCourseOpen(!isCourseOpen);

  const educationalBackground = [
    {
      degree: "Ph.D In Chemistry",
      date: "Sep 2000 - April 2004",
      institution: "University of Karachi",
    },
    {
      degree: "Masters in Biochemistry",
      date: "Sep 2000 - April 2004",
      institution: "University of Karachi",
    },
    {
      degree: "Bachelors in Chemistry",
      date: "Sep 2000 - April 2004",
      institution: "University of Karachi",
    },
  ];

  const workBackground = [
    "Chemistry Faculty Lecturer",
    "Research Assistant in Biochemistry",
  ];

  const offeredCourses = [
    {
      name: "Biochemistry and Its Applications in Industry",
      grade: "Grade 18",
      lessons: "19 Lessons",
      price: "$12/Class",
    },
    {
      name: "Organic Chemistry for Beginners",
      grade: "Grade 16",
      lessons: "15 Lessons",
      price: "$10/Class",
    },
  ];

  const reviews = [
    {
      reviewer: "Sarah Adams",
      role: "Chemistry Student",
      review:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.",
      rating: 4.8,
      image: img1,
    },
    {
      reviewer: "James Carter",
      role: "Biochemistry Student",
      review: "The teacher explains concepts in a very detailed and understandable way.",
      rating: 4.9,
      image: img2,
    },
  ];

  return (
    <div className="flex flex-col font-custom3 lg:flex-row gap-6 p-6 min-h-screen">
      {/* Left Section */}
      <div className="bg-white rounded-lg p-6 w-full lg:w-2/3">
        {/* Teacher Profile */}
        <div className="mb-6">
          <h2 className="text-xl font-medium">Teacher Profile</h2>
          <div className="flex items-center mt-4">
            <img className="w-16 h-16 rounded-full" src={img2} alt="" />
            <div className="ml-4">
              <h3 className="text-lg font-custom3 font-semibold">Hussain Aslam</h3>
              <p className="text-gray-600">Ph.D in Chemistry • Lecturer at Islamia College</p>
              <div className="flex items-center mt-1 text-yellow-500">
                <span className="mr-1">⭐</span>
                <span>4.8 rating (512 reviews)</span>
              </div>
            </div>
          </div>
        </div>

        {/* Educational Background */}
        <div className="mb-6 border-t pt-5">
          <button
            className="w-full text-left flex justify-between items-center font-medium text-lg"
            onClick={toggleEducational}
          >
            Educational Background
            <span>{isEducationalOpen ? "▼" : "▶"}</span>
          </button>
          {isEducationalOpen && (
            <ul className="mt-4 space-y-2">
              {educationalBackground.map((item, index) => (
                <li key={index}>
                  <div className="flex justify-between">
                    <span>{item.degree}</span>
                    <span className="text-gray-600">{item.date}</span>
                  </div>
                  <p className="text-sm text-gray-600">{item.institution}</p>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Work Background */}
        <div className="mb-6 border-t pt-5">
          <button
            className="w-full text-left flex justify-between items-center font-medium text-lg"
            onClick={toggleWork}
          >
            Work Background
            <span>{isWorkOpen ? "▼" : "▶"}</span>
          </button>
          {isWorkOpen && (
            <div className="mt-2 text-gray-600">
              {workBackground.map((work, index) => (
                <p key={index}>{work}</p>
              ))}
            </div>
          )}
        </div>

        {/* Offered Courses */}
        <div className="mb-6 border-t pt-5">
          <button
            className="w-full text-left flex justify-between items-center font-medium text-lg"
            onClick={toggleCourse}
          >
            Offered Courses
            <span>{isCourseOpen ? "▼" : "▶"}</span>
          </button>
          {isCourseOpen && (
            <div className="mt-4 space-y-4">
              {offeredCourses.map((course, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center p-4 rounded-lg"
                >
                  <div>
                    <p className="font-medium">{course.name}</p>
                    <p className="text-sm text-gray-600">
                      {course.grade} • {course.lessons}
                    </p>
                    <p className="text-primary">{course.price}</p>
                  </div>
                  <button className="px-4 py-2 bg-primary text-white rounded-full">
                    Enroll Class
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Right Section */}
      <div className="bg-white border-l text-gray-500 rounded-lg p-6 w-full lg:w-1/3">
        <h3 className="text-lg font-medium mb-4">Reviews</h3>
        <div className="space-y-4">
          {reviews.map((review, index) => (
            <div
              key={index}
              className="shadow-lg p-4 rounded-3xl space-x-4"
            >
              <div className="flex ml-3 gap-4 items-start">
                <img
                  className="w-12 h-12 rounded-full"
                  src={review.image}
                  alt=""
                />
                <div>
                  <h4 className="font-medium">{review.reviewer}</h4>
                  <p className="text-sm">{review.role}</p>
                </div>
              </div>
              <p className="mt-4 text-sm">{review.review}</p>
              <div className="flex items-center mt-4">
                <span className="mr-1">
                  <img src={star} alt="" />
                </span>
                <span>{review.rating}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeacherProfile;
