import React, { useState } from "react";
import img from '../../assets/enrollmentmodal.png'
const EnrollmentModal = () => {
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => setIsOpen(false);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white rounded-3xl shadow-xl max-w-md w-full p-6 relative">
            {/* Close Button */}
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            {/* Modal Content */}
            <div className="text-center">
              {/* Illustration */}
              <img
                src={img} // Replace this with the illustration URL
                alt="Enrollment Success"
                className="mx-auto mb-4"
              />

              {/* Success Message */}
              <h2 className="text-xl font-semibold mb-2">
                Yayyy!! You have successfully enrolled for the class!
              </h2>
              <p className="text-gray-500 text-sm mb-6">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </p>

              {/* Buttons */}
              <div className="flex justify-center gap-4">
                <button
                  onClick={closeModal}
                  className="px-4 py-2 border border-purple-500 text-purple-500 rounded-full hover:bg-purple-100 transition"
                >
                  Go Back
                </button>
                <button
                  onClick={() => alert("Go to Class")}
                  className="px-4 py-2 bg-purple-500 text-white rounded-full hover:bg-purple-600 transition"
                >
                  Go to Class
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EnrollmentModal;
