import React, { useState } from 'react';

const QuizComponent = () => {
  // Questions and options data
  const quizData = [
    {
      question: 'Who violated your boundary?',
      options: ['Family', 'Partner', 'Friends', 'Co-worker'],
      correctOption: 'Family',
    },
    {
      question: 'What is your preferred hobby?',
      options: ['Reading', 'Traveling', 'Gaming', 'Cooking'],
      correctOption: 'Reading',
    },
    {
      question: 'Which is your favorite season?',
      options: ['Spring', 'Summer', 'Autumn', 'Winter'],
      correctOption: 'Winter',
    },
    {
      question: 'What is your favorite color?',
      options: ['Red', 'Blue', 'Green', 'Yellow'],
      correctOption: 'Blue',
    },
    {
      question: 'Which animal do you like the most?',
      options: ['Dog', 'Cat', 'Bird', 'Fish'],
      correctOption: 'Dog',
    },
  ];

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOptions({
      ...selectedOptions,
      [currentQuestionIndex]: option,
    });
  };

  const handleNext = () => {
    if (currentQuestionIndex < quizData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setIsQuizCompleted(true);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const calculateResults = () => {
    let correctAnswers = 0;
    let incorrectAnswers = 0;

    quizData.forEach((question, index) => {
      if (selectedOptions[index] === question.correctOption) {
        correctAnswers++;
      } else {
        incorrectAnswers++;
      }
    });

    return {
      correctAnswers,
      incorrectAnswers,
      percentage: ((correctAnswers / quizData.length) * 100).toFixed(0),
      totalPoints: `${correctAnswers} / ${quizData.length}`,
    };
  };

  if (isQuizCompleted) {
    const results = calculateResults();

    return (
      <div className="p-4  min-h-screen">
        {/* Header */}
        <div className="flex items-center mb-6">
          <button
            className="text-gray-600 hover:text-gray-900 mr-4"
            onClick={() => setIsQuizCompleted(false)}
          >
            <i className="fas fa-arrow-left"></i>
          </button>
          <h1 className="text-xl font-medium text-gray-800 flex-grow">
            Day 01: Chapter 01
          </h1>
        </div>

        {/* Quiz Results */}
        <div className="bg-white rounded-lg shadow p-6 mb-6">
          <div className="flex items-center mb-4">
            <div className=" p-4 rounded-full mr-4">
              <i className="fas fa-trophy text-purple-600 text-2xl"></i>
            </div>
            <div>
              <p className="text-gray-800 font-medium text-lg">Quiz Score</p>
              <p className="text-gray-500 text-sm">Your performance summary</p>
            </div>
          </div>
          <div className="text-center">
            <p className="text-gray-800 text-sm font-semibold">
              Points: <span className="text-purple-600">{results.totalPoints}</span>
            </p>
            <p className="text-gray-800 text-sm font-semibold">
              Percentage: <span className="text-purple-600">{results.percentage}%</span>
            </p>
            <p className="text-gray-800 text-sm font-semibold">
              Duration: <span className="text-purple-600">00:10:15</span>
            </p>
          </div>
        </div>

        {/* Correct/Incorrect Answers */}
        <div className="mb-6">
          <p className="text-gray-800 text-sm font-medium mb-2">
            Correct Answers: <span className="text-green-600">{results.correctAnswers}</span>
          </p>
          <p className="text-gray-800 text-sm font-medium mb-2">
            Partially Correct Answers: <span className="text-blue-600">00</span>
          </p>
          <p className="text-gray-800 text-sm font-medium">
            Incorrect Answers: <span className="text-red-600">{results.incorrectAnswers}</span>
          </p>
        </div>

        {/* Buttons */}
        <div className="flex justify-between">
          <button
            className="bg-white border border-purple-600 text-purple-600 py-2 px-6 rounded-full shadow hover:bg-purple-50"
            onClick={() => {
              setSelectedOptions({});
              setCurrentQuestionIndex(0);
              setIsQuizCompleted(false);
            }}
          >
            Take Again
          </button>
          <button
            className="bg-purple-600 text-white py-2 px-6 rounded-full shadow hover:bg-purple-700"
            onClick={() => console.log('Go Back to Home')}
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }

  const currentQuestion = quizData[currentQuestionIndex];
  const selectedOption = selectedOptions[currentQuestionIndex];

  return (
    <div className="p-4  min-h-screen">
      {/* Header */}
      <div className="flex items-center mb-6">
        <button
          className="text-gray-600 hover:text-gray-900 mr-4"
          onClick={handlePrevious}
          disabled={currentQuestionIndex === 0}
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        <h1 className="text-xl font-medium text-gray-800 flex-grow">
          Day 01: Chapter 01
        </h1>
      </div>

      {/* Quiz Details */}
      <div className="mb-6">
        <p className="text-gray-500 text-sm">
          Total {quizData.length} Questions
        </p>
        <p className="text-gray-800 text-sm font-semibold">
          {currentQuestionIndex + 1}/{quizData.length}
        </p>
      </div>

      {/* Question */}
      <div className="mb-6">
        <p className="text-gray-800 font-medium text-lg mb-2">
          {currentQuestionIndex + 1}. {currentQuestion.question}
        </p>
        <p className="text-gray-500 text-sm">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor
        </p>
      </div>

      {/* Options */}
      <div className="mb-6">
        <p className="text-gray-700 text-sm font-medium mb-4">Select One Option</p>
        {currentQuestion.options.map((option, index) => (
          <div
            key={index}
            className={`flex items-center justify-between border rounded-lg p-4 mb-3 cursor-pointer ${
              selectedOption === option
                ? 'bg-purple-50 border-purple-500'
                : 'bg-white border-gray-300'
            }`}
            onClick={() => handleOptionSelect(option)}
          >
            <p className="text-gray-800">{option}</p>
            <div
              className={`w-5 h-5 rounded-full border-2 ${
                selectedOption === option
                  ? 'border-purple-500 bg-purple-500'
                  : 'border-gray-400'
              } flex items-center justify-center`}
            >
              {selectedOption === option && (
                <div className="w-2.5 h-2.5 rounded-full bg-white"></div>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-between">
        <button
          className="bg-white border border-purple-600 text-purple-600 py-2 px-6 rounded-full shadow hover:bg-purple-50 disabled:opacity-50"
          onClick={handlePrevious}
          disabled={currentQuestionIndex === 0}
        >
          Previous
        </button>
        <button
          className="bg-purple-600 text-white py-2 px-6 rounded-full shadow hover:bg-purple-700 disabled:opacity-50"
          onClick={handleNext}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default QuizComponent;
