import React from "react";
import { useTable } from "react-table";
import achievementimg from '../../assets/achievements.png'
import participationimg from '../../assets/participation.png'
import certificateimg from '../../assets/certificates.png'
const MyProgress = () => {
  // Data for Examination Results
  const examData = React.useMemo(
    () => [
      {
        subject: "Physics",
        session: "01",
        class: "12th Grade",
        date: "19/05/2023",
        time: "04:00 PM",
        type: "MCQs",
        marks: "90 / 100",
        status: "Pass",
      },
      {
        subject: "Mathematics",
        session: "01",
        class: "12th Grade",
        date: "19/05/2023",
        time: "06:00 PM",
        type: "Theory",
        marks: "60 / 75",
        status: "Pass",
      },
      {
        subject: "Mathematics",
        session: "01",
        class: "12th Grade",
        date: "19/05/2023",
        time: "06:00 PM",
        type: "Practical",
        marks: "45 / 60",
        status: "Fail",
      },
    ],
    []
  );

  // Columns for Examination Results
  const examColumns = React.useMemo(
    () => [
      { Header: "Subject", accessor: "subject" },
      { Header: "Session", accessor: "session" },
      { Header: "Class", accessor: "class" },
      { Header: "Date", accessor: "date" },
      { Header: "Time", accessor: "time" },
      { Header: "Type", accessor: "type" },
      { Header: "Marks Obt.", accessor: "marks" },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <span
            className={`px-3 py-1 rounded-full ${
              value === "Pass" ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"
            }`}
          >
            {value}
          </span>
        ),
      },
    ],
    []
  );

  // Data for Quiz Attempts
  const quizData = React.useMemo(
    () => [
      {
        subject: "Physics",
        session: "01",
        questions: 10,
        date: "19/05/2023",
        time: "04:00 PM",
        marks: "90 / 100",
        status: "Pass",
      },
      {
        subject: "Mathematics",
        session: "01",
        questions: 16,
        date: "19/05/2023",
        time: "06:00 PM",
        marks: "60 / 75",
        status: "Pass",
      },
    ],
    []
  );

  // Columns for Quiz Attempts
  const quizColumns = React.useMemo(
    () => [
      { Header: "Subject", accessor: "subject" },
      { Header: "Session", accessor: "session" },
      { Header: "Questions", accessor: "questions" },
      { Header: "Date", accessor: "date" },
      { Header: "Time", accessor: "time" },
      { Header: "Marks Obt.", accessor: "marks" },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <span
            className={`px-3 py-1 rounded-full ${
              value === "Pass" ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"
            }`}
          >
            {value}
          </span>
        ),
      },
    ],
    []
  );

  // React Table Hooks
  const examTable = useTable({ columns: examColumns, data: examData });
  const quizTable = useTable({ columns: quizColumns, data: quizData });

  // Render Table
  const renderTable = (tableInstance) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
    return (
      <table {...getTableProps()} className="w-full border-collapse text-sm">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="border-b">
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className="text-left p-2 text-gray-500">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="border-b">
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} className="p-2">
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div className="dashboard-container  min-h-screen p-8">
      {/* Progress Overview Section */}
      <div className="flex gap-4 ">
      <div className="progress-overview w-2/3 grid grid-cols-3 gap-4 mb-8">
        {[
          { title: "Achievements", value: "04", icon: achievementimg },
          { title: "Class Participation", value: "100%", icon: participationimg },
          { title: "Certificates", value: "03", icon: certificateimg },
        ].map((card, index) => (
          <div
            key={index}
            className="card flex items-center justify-between p-4 h-2/3 bg-white rounded-xl shadow"
          >
            <div className="flex gap-3 py-2 flex-col">
            <img src={card.icon} className="w-12" alt="" />
              <h4 className="text-gray-600 font-medium">{card.title}</h4>
              <p className="text-2xl font-bold text-primary">{card.value}</p>
            </div>
            
          </div>
        ))}
      
      </div>
      <div className="bg-white w-1/3 rounded-xl  p-6">
          <h3 className="font-semibold text-lg flex justify-between mb-4">Next Quiz <span className="text-gray-400 text-sm font-normal cursor-pointer">See all</span></h3>
          {["Physics Chapter 02", "Mathematics Chapter 02"].map((quiz, index) => (
            <div
              key={index}
              className="flex justify-between items-center  p-6  rounded-xl mb-3 shadow"
            >
              <div>
                <h4 className="font-medium">{quiz}</h4>
                <p className="text-gray-400 text-sm">19/05/2023</p>
              </div>
              <button className="text-primary font-semibold">Quiz</button>
            </div>
          ))}
          {/* <p className="text-right text-primary mt-4 cursor-pointer">See all</p> */}
        </div>
      </div>
      {/* Main Content Section */}
      <div className="">
        <p className="font-semibold text-lg flex justify-between mb-4">Progress</p>
        {/* Examination Result Reports */}
        <div className="col-span-2 my-4 bg-white rounded-xl shadow p-6">
          <h3 className="font-semibold text-lg mb-4">Examination Result Reports</h3>
          {renderTable(examTable)}
        </div>


       

        {/* Quiz Attempts */}
        <div className="col-span-3 bg-white rounded-xl shadow p-6">
          <h3 className="font-semibold text-lg mb-4">Quiz Attempts</h3>
          {renderTable(quizTable)}
        </div>
      </div>
    </div>
  );
};

export default MyProgress;
