import React from "react";

const ChatItem = ({ img,name, message, time }) => {
  return (
    <div className="flex items-start space-x-4 p-4 hover:bg-purple-50 rounded-lg shadow-lg cursor-pointer">
      <img
        src={img}
        alt="User Avatar"
        className="w-12 h-12 rounded-full"
      />
      <div>
        <h4 className="text-gray-700 font-medium">{name}</h4>
        <p className="text-sm text-gray-500">{message}</p>
        <span className="text-xs text-gray-400">{time}</span>
      </div>
    </div>
  );
};

export default ChatItem;
