import React, { useState } from 'react';

const OTPVerification = () => {
  const [otp, setOtp] = useState('');

  const handleChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`Verification code submitted: ${otp}`);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen  px-4">
      <div className="bg-white w-full max-w-md rounded-lg  p-6">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold text-gray-800">Digital Bank</h2>
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png"
            alt="Visa"
            className="w-10 h-10"
          />
        </div>
        <h3 className="text-lg font-medium text-gray-700 mt-4">
          Enter verification code
        </h3>
        <p className="text-sm text-gray-600 mt-1">
          We sent you a verification code by text message to (123) xxx-xx12.
          You have 6 attempts.
        </p>
        <form onSubmit={handleSubmit} className="mt-6">
          <label htmlFor="otp" className="text-sm text-gray-600">
            Verification code
          </label>
          <input
            type="text"
            id="otp"
            value={otp}
            onChange={handleChange}
            maxLength="6"
            className="w-full mt-2 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="123456"
          />
          <button
            type="submit"
            className="w-full bg-blue-600 text-white mt-4 py-2 rounded-lg hover:bg-blue-700"
          >
            Continue
          </button>
        </form>
        <button
          onClick={() => alert('Resending code...')}
          className="text-blue-600 text-sm mt-4 hover:underline"
        >
          Resend Code
        </button>
        <div className="mt-6 border-t pt-4 text-sm text-gray-600">
          <p className="mb-2">
            Having trouble?{' '}
            <a
              href="#"
              className="text-blue-600 hover:underline"
            >
              Choose another security option
            </a>
          </p>
          <p>
            Need Help?{' '}
            <button className="text-blue-600 font-bold text-lg hover:underline">
              +
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OTPVerification;
