import React, { useState } from "react";
import img from "../../assets/Group (3).png";
import img2 from "../../assets/Group.png";
import PaymentMethods from "./PaymentMethods";
import AccountProfile from "./AccountProfile";
import EditProfile from "./EditProfile";
import SocialAccounts from "./SocialAccounts";

const tabs = [
  { name: "Account Profile", icon: img },
  { name: "Payment Methods", icon: img2 },
  { name: "Social Accounts", icon: img2 },
  { name: "Password", icon: img2 },
  { name: "Notification Setting", icon: img2 },
  { name: "Privacy Policy", icon: img2 },
  { name: "Terms and Conditions", icon: img2 },
];

const ProfileSetting = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].name);

  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <aside className="w-1/4 bg-white border-r rounded-lg p-6">
        <h2 className="text-xl font-semibold text-gray-700 mb-6">Account Setting</h2>
        <ul className="space-y-4">
          {tabs.map((item, index) => (
            <li
              key={index}
              className={`flex items-center gap-3 py-3 px-4 rounded-lg text-gray-600 cursor-pointer ${
                activeTab === item.name
                  ? "bg-purple-100 text-purple-600 font-semibold"
                  : "hover:bg-gray-200 hover:text-gray-800"
              }`}
              onClick={() => setActiveTab(item.name)}
            >
              <img src={item.icon} alt="" />
              {item.name}
            </li>
          ))}
        </ul>
      </aside>

      {/* Profile Content */}
      <main className="w-3/4 p-8">
        {activeTab === "Account Profile" && <EditProfile />}
        {activeTab === "Payment Methods" && <PaymentMethods />}
        {activeTab === "Social Accounts" && <SocialAccounts />}
        {activeTab === "Password" && <PaymentMethods />}
        {activeTab === "Notification Setting" && <PaymentMethods />}
        {activeTab === "Privacy Policy" && <PaymentMethods />}
        {activeTab === "Terms and Conditions" && <PaymentMethods />}
      </main>
    </div>
  );
};

export default ProfileSetting;
