import React from "react";

const PaymentMethods = () => {
  const cards = [
    { type: "Visa", number: "5555 **** **** 5125", expiry: "05/24", status: "default" },
    { type: "Visa", number: "5555 **** **** 5125", expiry: "05/24", status: "set-default" },
    { type: "MasterCard", number: "5555 **** **** 5125", expiry: "05/24", status: "set-default" },
    { type: "MasterCard", number: "5555 **** **** 5125", expiry: "02/23", status: "expired" },
  ];

  return (
    <div className="min-h-screen flex flex-col items-center px-4 py-8">
      {/* Header */}
      <div className="w-full max-w-3xl flex justify-between items-center mb-8">
        <button className="text-gray-600 flex items-center">
          <i className="fas fa-arrow-left mr-2"></i> Payment Methods
        </button>
        <button className="bg-primary text-white px-4 py-2 rounded-lg shadow-md hover:bg-primary">
          Get Shareable Link
        </button>
      </div>

      {/* Card List */}
      <div className="w-full max-w-3xl bg-white  rounded-xl p-4 space-y-4">
        {cards.map((card, index) => (
          <div
            key={index}
            className="flex items-center justify-between shadow-md p-4 rounded-lg "
          >
            {/* Card Details */}
            <div className="flex items-center gap-4">
              <img
                src={
                  card.type === "Visa"
                    ? "https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png"
                    : "https://upload.wikimedia.org/wikipedia/commons/a/a4/Mastercard_2019_logo.svg"
                }
                alt={`${card.type} Logo`}
                className="w-12 "
              />
              <div>
                <p className="text-gray-800 font-medium">{card.number}</p>
                <p className="text-sm text-gray-600">Exp. Date: {card.expiry}</p>
              </div>
            </div>

            {/* Card Actions */}
            <div className="flex items-center gap-4">
              {card.status === "default" && (
                <span className="bg-gray-200 text-gray-600 px-3 py-1 rounded-full">
                  Default
                </span>
              )}
              {card.status === "set-default" && (
                <button className="text-primary font-medium">Set as Default</button>
              )}
              {card.status === "expired" && (
                <span className="bg-red-100 text-red-600 px-3 py-1 rounded-full">Expired</span>
              )}
              <button className="text-red-600">
                <i className="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Add New Card */}
      <button className="mt-8 bg-primary text-white px-6 py-3 rounded-lg shadow-md hover:bg-primary">
        Add New Card
      </button>
    </div>
  );
};

export default PaymentMethods;
