// src/components/BasicInfoForm.js
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
const EditProfile = () => {
  const [isBasicInfoOpen, setIsBasicInfoOpen] = useState(true);
  const [isEducationalInfoOpen, setIsEducationalInfoOpen] = useState(false);
  const [gender, setGender] = useState("male");
  // Data for the profile


  return (
    <div className="p-8  min-h-screen">
      <div className="mx-auto bg-white rounded-lg shadow-lg p-6">
        <h1 className="text-2xl font-semibold mb-6">Account Profile</h1>

        {/* Basic Info Accordion */}
        <div className="max-w-xl  w-full mb-4 border-b pb-4">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => setIsBasicInfoOpen(!isBasicInfoOpen)}
          >
            <h2 className="text-lg font-semibold">Basic Info</h2>
            <span className={`transform transition-transform ${isBasicInfoOpen ? "rotate-180" : ""}`}>
              ⌃
            </span>
          </div>
          {isBasicInfoOpen && (
            <div className="mt-4 text-sm text-gray-700">
                <form>
                    <div className="flex gap-4">
                        <input
                            type="text"
                            placeholder="First Name"
                            className="w-full px-4 py-3 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500"
                            required
                        />
                        <input
                            type="text"
                            placeholder="Last Name"
                            className="w-full px-4 py-3 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500"
                            required
                        />
                    </div>

                    <div className="flex gap-2 mt-4">
                        <input
                            type="text"
                            placeholder="DD"
                            className="w-1/3 px-4 py-3 border border-gray-300 rounded-full text-center focus:outline-none focus:ring-2 focus:ring-purple-500"
                            required
                        />
                        <input
                            type="text"
                            placeholder="MM"
                            className="w-1/3 px-4 py-3 border border-gray-300 rounded-full text-center focus:outline-none focus:ring-2 focus:ring-purple-500"
                            required
                        />
                        <input
                            type="text"
                            placeholder="YYYY"
                            className="w-1/3 px-4 py-3 border border-gray-300 rounded-full text-center focus:outline-none focus:ring-2 focus:ring-purple-500"
                            required
                        />
                    </div>

                    <div className="flex items-center  rounded-full border mt-4  w-full">
                        <button
                            type="button"
                            onClick={() => setGender("male")}
                            className={`flex-1 py-4 rounded-full   font-medium transition-all duration-300 ${gender === "male" ? "bg-primary text-white" : "text-gray-400"
                                }`}
                        >
                            Male
                        </button>
                        <button
                            type="button"
                            onClick={() => setGender("female")}
                            className={`flex-1 py-4 rounded-full  font-medium transition-all duration-300 ${gender === "female" ? "bg-primary text-white" : "text-gray-400"
                                }`}
                        >
                            Female
                        </button>
                    </div>
                    <div className="relative w-full mt-4">
      <PhoneInput
        country={"ca"} // Default country
        placeholder="XXXXXXXXXX"
        inputStyle={{
          width: "100%",
          paddingLeft: "4rem", // Space for the flag and code dropdown
          paddingRight: "1rem",
          paddingTop: "1.75rem",
          paddingBottom: "1.75rem",
          borderRadius: "9999px", // Full rounded
          borderColor: "#D1D5DB", // Gray border
          fontSize: "1rem",
          color: "#333",
          outline: "none",
          transition: "border-color 0.2s, box-shadow 0.2s",
        }}
        buttonStyle={{
          position: "absolute",
          left: "1rem",
          top: "50%",
          transform: "translateY(-50%)",
          backgroundColor: "transparent",
          border: "none",
          padding: "0",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
        dropdownStyle={{
          borderRadius: "0.5rem",
          overflow: "hidden",
        }}
        containerClass="w-full" // For responsiveness
      />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "4rem",
          transform: "translateY(-50%)",
          fontSize: "1rem",
          color: "#6B7280", // Gray text
        }}
      >

      </div>
    </div>

                    <select
                        className="w-full px-4 py-3 mt-4 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500 text-gray-500"
                        required
                    >
                        <option value="">-Select City-</option>
                        {/* Add more options as necessary */}
                    </select>
                    <input
                        type="text"
                        placeholder="Zip Code"
                        className="w-full px-4 py-3 mt-4 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500"
                        required
                    />
                    <input
                        type="text"
                        placeholder="Address"
                        className="w-full px-4 py-3 mt-4 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500"
                        required
                    />


                    
                </form>

            </div>
          )}
        </div>

        {/* Educational Info Accordion */}
        <div className="max-w-xl  w-full">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => setIsEducationalInfoOpen(!isEducationalInfoOpen)}
          >
            <h2 className="text-lg font-semibold">Educational Info</h2>
            <span className={`transform transition-transform ${isEducationalInfoOpen ? "rotate-180" : ""}`}>
              ⌃
            </span>
          </div>
          {isEducationalInfoOpen && (
            <div className="mt-4 text-sm text-gray-700">
          <input
                        type="text"
                        placeholder="School Name"
                        className="w-full px-4 py-3 mt-4 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500"
                        required
                    />

                    <select
                        className="w-full px-4 py-3 mt-4 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500 text-gray-500"
                        required
                    >
                        <option value="">-Select Curriculum-</option>
                        {/* Add more options as necessary */}
                    </select>

                    <select
                        className="w-full px-4 py-3 mt-4 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500 text-gray-500"
                        required
                    >
                        <option value="">-Select Grade-</option>
                        {/* Add more options as necessary */}
                    </select>
           
            </div>
          )}
        <Link to='/auth/verification'>
                    <button
                        type="submit"
                        className="w-full bg-primary text-white py-4 rounded-full font-semibold mt-6"
                    >
                        Continue
                    </button></Link>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
