import React from 'react'

const SocialAccounts = () => {
  return (
        <div className="p-8  min-h-screen">
    <div className="mx-auto bg-white rounded-lg shadow-lg p-6">
      <h1 className="text-2xl font-semibold mb-6">Social Accounts</h1>
       <div className="mt-4 max-w-xl text-sm text-gray-700">
    <input
                  type="text"
                  placeholder="https://www.facebook.com/profile325116474..."
                  className="w-full px-4 py-3 mt-4 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500"
                  required
              />
    <input
                  type="text"
                  placeholder="https://www.twitter.com/profile325116474..."
                  className="w-full px-4 py-3 mt-4 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500"
                  required
              />
    <input
                  type="text"
                  placeholder="https://www.linkedin.com/profile325116474..."
                  className="w-full px-4 py-3 mt-4 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500"
                  required
              />
    <input
                  type="text"
                  placeholder="https://www.linkedin.com/profile325116474..."
                  className="w-full px-4 py-3 mt-4 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500"
                  required
              />

          
      </div>
      </div>
      </div>
  )
}

export default SocialAccounts