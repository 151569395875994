import React from 'react';
import bgimg from "../../assets/dahsboard-books.png";
const PaymentForm = ({ isVisible, onClose }) => {
  return isVisible ? (
    <div className="min-h-screen flex items-center justify-center">
      <div className="bg-white rounded-lg  p-6 w-full max-w-md">
        <h2 className="text-lg font-semibold mb-4">Payment</h2>

        {/* Personal Details Section */}
        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-2">Personal Details</h3>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Full Name"
              className="w-full border border-gray-300 rounded-full px-4 py-4 text-sm focus:ring focus:ring-purple-500 focus:outline-none"
            />
          </div>
          <div className="mb-4">
            <input
              type="email"
              placeholder="Email Address"
              className="w-full border border-gray-300 rounded-full px-4 py-4 text-sm focus:ring focus:ring-purple-500 focus:outline-none"
            />
          </div>
          <div className="flex items-center gap-2 mb-4">
            <div className="flex items-center border border-gray-300 rounded-lg px-3 py-2">
              <img
                src="https://flagcdn.com/w40/ca.png"
                alt="Country Code"
                className="w-5 h-5 mr-2"
              />
              <span>001</span>
            </div>
            <input
              type="text"
              placeholder="XXXXXXXXXX"
              className="flex-1 border border-gray-300 rounded-full px-4 py-4 text-sm focus:ring focus:ring-purple-500 focus:outline-none"
            />
          </div>
        </div>

        {/* Payment Details Section */}
        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-2">Payment Details</h3>
          <div className="flex items-center mb-4">
            <input type="checkbox" id="paymentLink" className="mr-2" />
            <label htmlFor="paymentLink" className="text-sm text-gray-600">
              Provide payment link for my parents
            </label>
          </div>
          <div className="text-sm text-gray-500 mb-2">Or Choose Self-Payment</div>
          <div className="flex items-center gap-4 mb-4">
            <div className="flex items-center">
              <input
                type="radio"
                name="paymentMethod"
                id="mastercard"
                className="mr-2"
              />
              <label htmlFor="mastercard">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Mastercard_2019_logo.svg"
                  alt="Mastercard"
                  className="h-6"
                />
              </label>
            </div>
            <div className="flex my-2 items-center">
              <input
                type="radio"
                name="paymentMethod"
                id="visa"
                className="mr-2"
              />
              <label htmlFor="visa">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png"
                  alt="Visa"
                  className="h-6"
                />
              </label>
            </div>
          </div>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Card Holder Name"
              className="w-full border border-gray-300 rounded-full px-4 py-4 text-sm focus:ring focus:ring-purple-500 focus:outline-none"
            />
          </div>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Card Number"
              className="w-full border border-gray-300 rounded-full px-4 py-4 text-sm focus:ring focus:ring-purple-500 focus:outline-none"
            />
          </div>
          <div className="flex gap-4 mb-4">
            <input
              type="text"
              placeholder="Expiry Date"
              className="flex-1 border border-gray-300 w-[50%] rounded-full px-4 py-4 text-sm focus:ring focus:ring-purple-500 focus:outline-none"
            />
            <input
              type="text"
              placeholder="CVV Code"
              className="flex-1 border border-gray-300 w-[50%] rounded-full px-4 py-4 text-sm focus:ring focus:ring-purple-500 focus:outline-none"
            />
          </div>
        </div>

        {/* Agreement Section */}
        <div className="mb-4">
          <div className="flex items-center">
            <input type="checkbox" id="notRobot" className="mr-2" />
            <label htmlFor="notRobot" className="text-sm text-gray-600">
              I'm not a robot
            </label>
          </div>
        </div>
        <div className="mb-4">
          <div className="flex items-center">
            <input type="checkbox" id="terms" className="mr-2" />
            <label htmlFor="terms" className="text-sm text-gray-600">
              I accept all <a href="#" className="text-purple-500 underline">Terms & Conditions</a>
            </label>
          </div>
        </div>
        <div className='mx-auto w-[60%] '>
        {/* Submit Button */}
        <button className=" w-full bg-purple-500 text-white py-3 rounded-full text-sm font-medium hover:bg-purple-600 transition">
          Proceed to Checkout
        </button></div>
      </div>
    </div>
  ): (
    <div className="  flex flex-col border-l h-screen  items-center justify-center">
    <img src={bgimg} alt="No Item Selected" className="w-40 h-40 mb-4" />
    <p className="text-gray-500 text-sm">No Item Selected</p>
  </div>
);
};

export default PaymentForm;
