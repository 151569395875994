import React, { useState } from "react";

import ChatItem from "../components/Dashboard/ChatItem";

import img1 from '../assets/person3.png'
import chatimg from '../assets/chatimg.png'

const ChatPage = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => setMenuOpen(!menuOpen);
    const chats = [
        { id: 1, name: "James Brody", message: "Hi, did you do your assign...", time: "1 min ago", img: img1 },
        { id: 2, name: "Lisa Angela", message: "Hi, did you do your assign...", time: "1 min ago", img: img1 },
    ];
    return (
        <div className="flex h-screen ">
            {/* Sidebar */}
            <div className="w-1/3 bg-white shadow-lg p-4 flex flex-col">
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold text-gray-700">Chats</h2>
                    <div className="relative">
                        <button onClick={toggleMenu} className="text-gray-500">
                            <i className="fas fa-ellipsis-v">...</i>
                        </button>
                        {menuOpen && (
                            <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg z-10">
                                <ul className="text-sm text-gray-700">
                                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Compose New Chat</li>
                                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Mark as Read</li>
                                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Delete Chats</li>
                                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Setting</li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <div className="mt-4">
                    <input
                        type="text"
                        placeholder="Search"
                        className="w-full px-4 py-2 border rounded-full bg-gray-100 text-sm"
                    />
                </div>


                <div className="mt-4 flex flex-col space-y-4">
                    {chats.map((chat) => (
                        <ChatItem
                            key={chat.id}
                            img={chat.img}
                            name={chat.name}
                            message={chat.message}
                            time={chat.time}
                        />
                    ))}
                </div>
            </div>

            {/* Chat Content */}
            <div className="flex-1 flex items-center justify-center">
                <div className="text-center">
                    <div className="w-32 h-32 mx-auto mb-4">
                        <img
                            src={chatimg}
                            alt="No Chat Selected"
                            className="w-full h-full"
                        />
                    </div>
                    <p className="text-gray-500 text-sm">No Chat Selected</p>
                </div>
            </div>
        </div>
    );
};

export default ChatPage;
